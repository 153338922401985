import { createContext, useContext, useMemo, useState } from 'react'

import type { Customisations } from '@lorikeetai/widget/types'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  CodeBlock,
  Heading,
  Pill,
  Tag,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TypingLoader,
  cn,
} from '@optechai/design-system'
import lorikeetAvatar from '@optechai/design-system/assets/lorikeet_logomark_color.svg'
import { Link } from '@remix-run/react'
import { format } from 'date-fns'
import { AlertTriangleIcon, Info } from 'lucide-react'
import { observer } from 'mobx-react-lite'

import type {
  CloseTicketReason,
  EscalationReason,
  MessageCheckResult,
  ProcessingCancellationReason,
  SmallTalkCategory,
} from '@server/models/ticketEvent/ticketEvent.dto'
import type { TicketMessageAttachment } from '@server/models/ticketMessage/ticketMessageAttachment.dto'
import type {
  TicketActionResultForQA,
  TicketActionsResultsForQA,
  TicketEventForQA,
  MessageType,
} from '@server/services/ticket/ticket'
import { markdownToReact } from '@web/services/markdown'

import { getUserDisplayName } from '../../helpers/format'
import { CsatForm } from '../csat-form/csat-form'
import { MediaCard } from '../media-card/media-card'
import {
  WorkflowNodeItem,
  WorkflowNodePill,
} from '../primitive/workflow-node-type'
import { TicketContext } from '../tickets/ticket/context'
import { Tool } from '../tool/tool'
import type { WorkflowNodeTypeAndNew } from '../workflow-node-card'

export type { MessageType }

type Person = {
  firstName?: string
  lastName?: string
  email?: string
}

export type BasicNodeDetails = {
  id: string
  displayName: string
  type: WorkflowNodeTypeAndNew
}

export type MessageProps = {
  /**
   * Unique identifier for the message. A uuid.
   */
  id: string
  /**
   * Message content in most scenarios this will be markdown or HTML depending on where it originated.
   */
  content: string
  messageType: MessageType
  /**
   * Name of the subscriber who sent the message. Translated from the subscriber model.
   */
  subscriberName?: string
  customer?: Person
  user?: Person
  isFromBot?: boolean
  /** stringified date obj */
  createdAt?: string
  remoteCreatedAt?: string
  originNodes?: BasicNodeDetails[]
  /**
   * Any attachments that are associated with the message.
   */
  attachments?: TicketMessageAttachment[]
}

type MessageListProps = {
  /**
   * Is loading.
   */
  isLoading?: boolean
  /**
   * Collection of messages in the conversation.
   */
  messages: MessageProps[]
  /**
   * Additional classNames for the component.
   */
  className?: string
  /**
   * Events related to the ticket.
   */
  events: TicketEventForQA[]
  actions: TicketActionsResultsForQA

  customerDisplayName?: string
  ticketTitle?: string
  ticketRemoteId?: string
  voiceRecordingUrl?: string
}

type MessageAlignment = 'left' | 'right'

type MessageAuthor = {
  src?: string
  initials: string
  align: MessageAlignment
  name: string
}

type MessageItemProps = {
  author: MessageAuthor
  attachments?: TicketMessageAttachment[]
  content?: string
  isLoading?: boolean
  isDraft?: boolean
  isBot?: boolean
  isInternal?: boolean
  isUnknown?: boolean
  createdAt?: string
  remoteCreatedAt?: string
  originNodes?: BasicNodeDetails[]
  appearance?: 'primary' | 'secondary' | 'neutral'
}

export const CustomisationContext = createContext<Customisations | undefined>(
  undefined,
)
const InvertAlignmentContext = createContext<boolean | undefined>(false)

const LORIKEET_AUTHOR = {
  src: lorikeetAvatar,
  initials: 'LK',
  align: 'right',
  name: 'Lori Keet',
} satisfies MessageAuthor

const NodeAttributionPill = ({ node }: { node: BasicNodeDetails }) => {
  if (node.type === 'Return') {
    return null
  }

  return (
    <Tooltip>
      <TooltipTrigger>
        <WorkflowNodePill type={node.type} />
      </TooltipTrigger>
      <TooltipContent>
        <WorkflowNodeItem label={node.displayName} type={node.type} />
      </TooltipContent>
    </Tooltip>
  )
}

// Arbitrary. Change as needed.
const MAX_CONTENT_LENGTH = 500

/**
 * __MessageItem__
 *
 * A single message item in the conversation.
 */
const MessageItem = ({
  author,
  content,
  isDraft,
  isInternal,
  isUnknown,
  isLoading,
  createdAt,
  remoteCreatedAt,
  originNodes,
  appearance = 'neutral',
  attachments = [],
}: MessageItemProps) => {
  const message = useMemo(() => {
    if (!content) return null
    return markdownToReact(content)
  }, [content])
  // We show the remoteCreatedAt value, unless it's not available, in which case
  // we fall through to the createdAt string
  const dateStr = remoteCreatedAt
    ? format(remoteCreatedAt, 'pp, d MMM yyyy')
    : format(createdAt || new Date(), 'pp, d MMM yyyy')
  const [showFullContent, setShowFullContent] = useState(false)
  const isInvertedAlignment = useContext(InvertAlignmentContext)
  const contextualAlignment = isInvertedAlignment
    ? author.align === 'left'
      ? 'right'
      : 'left'
    : author.align

  return (
    <div
      className={cn(
        contextualAlignment === 'left' ? 'mr-auto' : 'ml-auto',
        'flex min-w-64 max-w-md items-end gap-m',
        isLoading && 'min-w-8',
      )}
    >
      {contextualAlignment === 'left' && (
        <Avatar appearance={appearance}>
          <AvatarImage
            alt={author.name}
            className={author.name === LORIKEET_AUTHOR.name ? 'p-xs' : ''}
            src={author.src}
          />
          <AvatarFallback>{author.initials.toUpperCase()}</AvatarFallback>
        </Avatar>
      )}

      {isLoading ? (
        <div
          className={cn(
            'rounded-lg bg-neutral px-m py-m',
            isInvertedAlignment ? 'mr-auto' : 'ml-auto',
          )}
        >
          <TypingLoader />
        </div>
      ) : (
        <div
          className={cn(
            'flex flex-1 flex-col gap-y-m overflow-x-hidden break-words rounded-lg px-m py-m font-text-s',
            appearance === 'primary' && 'bg-primary-subtle',
            appearance === 'secondary' && 'bg-secondary-subtle',
            appearance === 'neutral' && 'bg-neutral',
          )}
        >
          {isDraft && (
            <Pill className="self-start" size="small" variant="white">
              Draft
            </Pill>
          )}
          {isInternal && (
            <Pill className="self-start" size="small" variant="white">
              Internal
            </Pill>
          )}
          {isUnknown && (
            <Pill className="self-start" size="small" variant="white">
              Unknown
            </Pill>
          )}
          {attachments.length > 0 && (
            <div className="flex flex-col gap-y-s">
              {/* Intention to add a smarter 'Media' component to deal with non-image assets */}
              {attachments.map((attachment) => (
                <MediaCard key={attachment.id} {...attachment} />
              ))}
            </div>
          )}
          <div className="prose-sm prose-p:my-l first-of-type:prose-p:mt-0 last-of-type:prose-p:mb-0 prose-li:list-disc">
            {isInternal ? (
              content && content.length > MAX_CONTENT_LENGTH ? (
                <>
                  <span
                    className={cn(
                      showFullContent === false
                        ? 'line-clamp-[10]'
                        : 'line-clamp-none',
                    )}
                  >
                    {message}
                  </span>
                  <div className="mt-m flex justify-center">
                    <Button
                      onClick={() => setShowFullContent((prev) => !prev)}
                      size="small"
                      type="button"
                      variant="outline"
                    >
                      {`Show ${showFullContent ? 'less' : 'more'}`}
                    </Button>
                  </div>
                </>
              ) : (
                message
              )
            ) : (
              message
            )}
          </div>
          <div className="flex flex-row flex-wrap items-center justify-between">
            {author.name ? (
              <Text
                className="text-subtle"
                suppressHydrationWarning
                variant="p-xs"
              >
                {author.name}
                {dateStr && ` | ${dateStr}`}
              </Text>
            ) : (
              <Text
                className="text-subtle"
                suppressHydrationWarning
                variant="p-xs"
              >
                {dateStr && `${dateStr}`}
              </Text>
            )}
            {originNodes && originNodes.length > 0 && (
              <div className="flex max-w-full gap-xs">
                {originNodes.map((node) => (
                  <NodeAttributionPill key={node.id} node={node} />
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      {contextualAlignment === 'right' && (
        <Avatar appearance={appearance}>
          <AvatarImage
            alt={author.name}
            className={author.name === LORIKEET_AUTHOR.name ? 'p-xs' : ''}
            src={author.src}
          />
          <AvatarFallback>{author.initials.toUpperCase()}</AvatarFallback>
        </Avatar>
      )}
    </div>
  )
}

const LorikeetMessage = ({
  message,
  isInternal,
  isDraft,
  isLoading,
}: {
  isLoading?: boolean
  message?: MessageProps
  isInternal?: boolean
  isDraft?: boolean
}) => {
  const customisation = useContext(CustomisationContext)
  const author = {
    ...LORIKEET_AUTHOR,
  }

  if (customisation?.logoUrl) {
    author.src = customisation.logoUrl
  }

  if (customisation?.botName) {
    author.name = customisation.botName
  }

  return (
    <MessageItem
      author={author}
      content={message?.content}
      createdAt={message?.createdAt}
      isBot={true}
      isDraft={isDraft}
      isInternal={isInternal}
      isLoading={isLoading}
      originNodes={message?.originNodes}
      remoteCreatedAt={message?.remoteCreatedAt}
    />
  )
}

const UserMessage = ({
  message,
  isInternal,
}: {
  message: MessageProps
  isInternal?: boolean
}) => {
  let authorInitials = '?'
  let authorName = isInternal ? 'Internal message' : 'Unknown'
  if (message.user) {
    authorInitials =
      message.user.firstName && message.user.lastName
        ? `${message.user.firstName[0]}${message.user.lastName[0]}`
        : (message.subscriberName?.slice(0, 3).toUpperCase() ?? 'A')

    authorName =
      message.user.firstName && message.user.firstName !== ''
        ? message.user.firstName
        : (message.subscriberName ?? 'Agent')
  }
  return (
    <MessageItem
      appearance="secondary"
      author={{
        initials: authorInitials,
        align: 'right',
        name: authorName,
      }}
      content={message.content}
      createdAt={message.createdAt}
      isInternal={isInternal}
      originNodes={message.originNodes}
      remoteCreatedAt={message.remoteCreatedAt}
    />
  )
}

const CustomerMessage = ({ message }: { message: MessageProps }) => {
  const { customer } = message
  const customerAvatarInitials = customer
    ? customer.firstName && customer.lastName
      ? `${customer.firstName[0]}${customer.lastName[0]}`
      : customer.email
        ? customer.email[0]
        : 'C'
    : 'C'
  return (
    <MessageItem
      appearance="primary"
      attachments={message.attachments}
      author={{
        initials: customerAvatarInitials!,
        align: 'left',
        name: customer
          ? `${customer.firstName} ${customer.lastName}`.trim()
          : 'Customer',
      }}
      content={message.content}
      createdAt={message.createdAt}
      remoteCreatedAt={message.remoteCreatedAt}
    />
  )
}

const UnknownMessage = ({ message }: { message: MessageProps }) => {
  return (
    <MessageItem
      appearance="secondary"
      author={{
        initials: '?',
        align: 'right',
        name: 'Unknown',
      }}
      content={message.content}
      createdAt={message.createdAt}
      isUnknown={true}
      originNodes={message.originNodes}
      remoteCreatedAt={message.remoteCreatedAt}
    />
  )
}

type HumanReadableEvent = {
  message: string
  explanation?: string
  llmExplanation?: React.ReactNode
}

export const HumanReadableEscalationEvent = ({
  reason,
}: {
  reason: EscalationReason | undefined
}): HumanReadableEvent => {
  let explanation
  let message = 'Ticket escalated'

  switch (reason) {
    case 'WORKFLOW_ERROR':
      explanation = 'We hit a workflow error :('
      break
    case 'NO_WORKFLOW_MATCHES':
      message = 'Ticket handed off'
      explanation = "We couldn't find a workflow that matched any of the topics"
      break
    case 'WORKFLOW_SPLITTER':
      message = 'Ticket handed off'
      explanation =
        'Matched to a workflow being rolled out. Tagging and triage workflows will still be run'
      break
    case 'WORKFLOW_POWER_UP':
      message = 'Ticket handed off'
      explanation = 'We escalated per a configured workflow node'
      break
    case 'UNABLE_TO_GATHER_INFO_FROM_CUSTOMER':
      explanation =
        'We were unable to gather all required information from the customer'
      break
    case 'CUSTOMER_REQUESTED_HUMAN':
      explanation = 'The customer asked to talk to a human'
      break
    case 'CUSTOMER_UPLOADED_ATTACHMENT':
      explanation = 'The customer uploaded an attachment'
      break
    case 'REPEATED_STATIC_RESPONSE':
      explanation = 'We were going to repeat the same response to the customer'
      break
    case 'RESPONSE_NODE_REPEATED_TOO_MANY_TIMES':
      explanation = 'We were going to run the same response node again'
      break
    case 'MATCHED_SAME_WORKFLOW':
      explanation = 'We were going to repeat a previously run workflow'
      break
    case 'COULDNT_ANSWER_CUSTOMER_WITH_AVAILABLE_INFO':
      message = 'Ticket handed off'
      explanation =
        "Lorikeet AI didn't think the response it had was enough to fully solve the customer's question"
      break
    case 'INVALID_RESPONSE':
      message = 'Ticket handed off'
      explanation = 'LLM genenerated an invalid response'
      break
    case 'OPTECH_DID_NOT_RESPOND':
      explanation = "We haven't responded to this ticket"
      break
    case 'NO_REFERENCE_MATERIAL':
      explanation = 'We could not find any reference material'
      break
    case 'POOR_REFERENCE_MATERIAL':
      explanation = 'We could not find any relevant reference material'
      break
    case 'REFERENCE_MATERIAL_RETRIEVAL_ERROR':
      explanation = 'We had an error retrieving reference material'
      break
    case 'CUSTOMER_AUTH_TOKEN_FETCH_ERROR':
      explanation = 'We had an error fetching the customer auth token'
      break
    case 'MULTIPLE_STATICALLY_PRODUCED_REPLY_TO_CUSTOMER_ACTIONS':
      explanation =
        'We needed to answer multiple customer questions, but one had a statically produced reply'
      break
    case 'NOT_ALL_TOPIC_WORKFLOWS_PRODUCED_REPLY_TO_CUSTOMER_ACTIONS':
      explanation =
        'We needed to answer multiple customer questions, but not all workflows produced a reply'
      break
    default:
      break
  }
  return { message, explanation }
}

export const HumanReadableMessageCheckFailedEvent = ({
  failedChecks,
}: {
  failedChecks: MessageCheckResult[] | undefined
}): HumanReadableEvent => {
  if (!failedChecks) {
    // Shouldn't really ever happen, but just in case
    return {
      message: 'Message checks failed',
      explanation: 'Checks run on customer messages failed',
    }
  }

  const message =
    failedChecks.length > 1
      ? `${failedChecks.length} message checks failed`
      : 'Message check failed'

  const explanation = failedChecks
    .map(
      (check) =>
        `${check.name} failed with ${check.met === 'yes' ? 'high' : 'moderate'} confidence: ${check.explanation}`,
    )
    .join('\n')

  return {
    message,
    explanation,
  }
}

export const HumanReadableClosedTicketEvent = ({
  reason,
}: {
  reason: CloseTicketReason | undefined
}): HumanReadableEvent => {
  let explanation

  switch (reason) {
    case 'ESCALATION_CONFIG':
      explanation = 'Closed ticket due to out of hours escalation settings.'
      break
    case 'USER_ACTION':
      explanation =
        'This could be a user closing the ticket, or an automation that closed the ticket.'
      break
    case 'NO_OPEN_TOPICS':
      explanation = 'Closed because the customer raised no new topics'
      break
    case 'WAITING_FOR_CUSTOMER_RESPONSE_TIMEOUT':
      explanation = 'Closed because the customer did not respond before timeout'
      break
    case undefined:
      break
    default:
      const _exhaustiveCheck: never = reason
      break
  }

  return {
    message: 'Closing ticket',
    explanation,
  }
}

export const HumanReadableProcessingCancellationEvent = ({
  reason,
}: {
  reason: ProcessingCancellationReason | undefined
}): HumanReadableEvent => {
  let explanation
  const message = 'Ticket processing cancelled'

  switch (reason) {
    case 'EXCLUDED_TAGS':
      explanation =
        'Excluded tags were added to the ticket since we started processing'
      break
    case 'EXCLUDED_TAGS_RESPONSE':
      explanation =
        'Tags to exclude from responding were added to the ticket since we started processing'
      break
    case 'CANCELLED_TRIAGE_IN_FAVOUR_OF_RESPONSE':
      explanation =
        'We stopped triaging the ticket in favor of responding to the customer'
      break
    case 'ASSIGNED_TO_SOMEONE_ELSE':
      explanation = 'The ticket was assigned to someone else'
      break
    case 'TERMINAL_STATUS':
      explanation = 'Ticket reached a terminal status'
      break
    case 'PREVENT_REPEATED_EMAIL_RESPONSES':
      explanation =
        'We were going to respond to the customer, but we already did'
      break
    case 'UNKNOWN_MESSAGE_TYPE':
      explanation = 'We received a message of unknown type'
      break
    case 'NO_CUSTOMER_MESSAGES':
      explanation = 'Ticket has no customer messages'
      break
    case 'NO_MESSAGES':
      explanation = 'Ticket has no messages'
      break
    case 'NO_MESSAGE_CONTENT':
      explanation = 'Ticket has no message content'
      break
    case 'NO_INTERNAL_NOTES':
      explanation = 'Waiting for an internal note'
      break
    case 'NOT_ENDING_IN_CUSTOMER_MESSAGE':
      explanation = 'Ticket does not end in a customer message'
      break
    case 'AGENT_MESSAGE_ALREADY_PRESENT':
      explanation = 'Ticket already has a message from an agent'
      break
    case 'DATA_SOURCE_CANNOT_ACTION_TICKETS':
      explanation = 'Ticketing system integration is currently read-only'
      break
    case 'MISSING_REQUIRED_TAGS':
      explanation = 'Ticket is missing required tags'
      break
    case 'ENDS_WITH_BOT_RESPONSE':
      explanation = 'Ticket ends with a bot response'
      break
    case 'EXCLUDED_BY_EMAIL_IGNORE_LIST':
      explanation = 'Ticket is excluded by the email ignore list'
      break
    case 'UNEXPECTED_MESSAGE_COUNT':
      explanation = 'Ticket has an unexpected number of messages'
      break
    case undefined:
      break
    default:
      const _exhaustiveCheck: never = reason
      break
  }
  return { message, explanation }
}

export const HumanReadableEscalationRequestEvent = ({
  explanation,
}: {
  explanation: string | undefined
}): HumanReadableEvent => {
  return {
    message: 'Ticket escalated due to request for human',
    llmExplanation: explanation ? (
      <div className="flex flex-col gap-y-s">
        <Text variant="p-s-bold">Explanation from model:</Text>
        <CodeBlock>{explanation}</CodeBlock>
      </div>
    ) : undefined,
  }
}

export const HumanReadableHostileMessageEvent = ({
  explanation,
}: {
  explanation: string | undefined
}): HumanReadableEvent => {
  return {
    message: 'Replied to hostile message',
    llmExplanation: explanation ? (
      <div className="flex flex-col gap-y-s">
        <Text variant="p-s-bold">Explanation from model:</Text>
        <CodeBlock>{explanation}</CodeBlock>
      </div>
    ) : undefined,
  }
}

export const HumanReadableSmallTalkEvent = ({
  category,
  explanation,
}: {
  category: SmallTalkCategory | undefined
  explanation: string | undefined
}): HumanReadableEvent => {
  let message = undefined
  switch (category) {
    case 'GREETING':
      message = 'Replied to greeting'
      break
    case 'INCOMPLETE_QUESTION':
      message = 'Replied to incomplete question'
      break
    default:
      message = 'Replied to small talk'
  }
  return {
    message,
    llmExplanation: explanation ? (
      <div className="flex flex-col gap-y-s">
        <Text variant="p-s-bold">Explanation from model:</Text>
        <CodeBlock>{explanation}</CodeBlock>
      </div>
    ) : undefined,
  }
}

export const HumanReadableDisambiguationEvent = ({
  ambiguousTopic,
  explanation,
}: {
  ambiguousTopic: string | undefined
  explanation: string | undefined
}): HumanReadableEvent => {
  return {
    message: ambiguousTopic
      ? `Disambiguating topic: ${ambiguousTopic}`
      : 'Disambiguating topic',
    llmExplanation: explanation ? (
      <div className="flex flex-col gap-y-s">
        <Text variant="p-s-bold">Explanation from model:</Text>
        <CodeBlock>{explanation}</CodeBlock>
      </div>
    ) : undefined,
  }
}

const TicketEventMessage = ({ event }: { event: TicketEventForQA }) => {
  let message, explanation, llmExplanation
  switch (event.type) {
    case 'ASSIGNED':
      {
        const userIdentifier =
          getUserDisplayName(event.assigneeUser) ?? event.assigneeRemoteId
        message =
          event.assigneeRemoteId === null || event.assigneeRemoteId === ''
            ? 'Ticket unassigned'
            : `Ticket reassigned to ${userIdentifier}`
      }
      break
    case 'ESCALATED':
      {
        // Context for picking these messages:
        // https://optechai.slack.com/archives/C073DP3UHLM/p1718692263035419
        const {
          message: humanReadableMessage,
          explanation: humanReadableExplanation,
        } = HumanReadableEscalationEvent({
          reason: event.escalationReason,
        })

        message = humanReadableMessage
        explanation = humanReadableExplanation
      }
      break
    case 'PROCESSING_CANCELLED':
      {
        const {
          message: humanReadableMessage,
          explanation: humanReadableExplanation,
        } = HumanReadableProcessingCancellationEvent({
          reason: event.cancellationReason,
        })

        message = humanReadableMessage
        explanation = humanReadableExplanation
      }
      break
    case 'ESCALATION_REQUEST':
      {
        const {
          message: humanReadableMessage,
          llmExplanation: humanReadableLlmExplanation,
        } = HumanReadableEscalationRequestEvent({
          explanation: event.explanation,
        })
        message = humanReadableMessage
        llmExplanation = humanReadableLlmExplanation
      }
      break
    case 'HOSTILE_MESSAGE':
      {
        const {
          message: humanReadableMessage,
          llmExplanation: humanReadableLlmExplanation,
        } = HumanReadableHostileMessageEvent({
          explanation: event.explanation,
        })
        message = humanReadableMessage
        llmExplanation = humanReadableLlmExplanation
      }
      break
    case 'SMALL_TALK':
      {
        const {
          message: humanReadableMessage,
          llmExplanation: humanReadableLlmExplanation,
        } = HumanReadableSmallTalkEvent({
          category: event.category,
          explanation: event.explanation,
        })
        message = humanReadableMessage
        llmExplanation = humanReadableLlmExplanation
      }
      break
    case 'DISAMBIGUATION':
      {
        const {
          message: humanReadableMessage,
          llmExplanation: humanReadableLlmExplanation,
        } = HumanReadableDisambiguationEvent({
          ambiguousTopic: event.ambiguousTopic,
          explanation: event.explanation,
        })
        message = humanReadableMessage
        llmExplanation = humanReadableLlmExplanation
      }
      break
    case 'CLOSED':
      {
        const {
          message: humanReadableMessage,
          explanation: humanReadableExplanation,
        } = HumanReadableClosedTicketEvent({
          reason: event.closeReason,
        })

        message = humanReadableMessage
        llmExplanation = humanReadableExplanation
      }
      break
    case 'MESSAGE_CHECK_FAILED':
      {
        const {
          message: humanReadableMessage,
          explanation: humanReadableExplanation,
        } = HumanReadableMessageCheckFailedEvent({
          failedChecks: event.failedChecks,
        })

        message = humanReadableMessage
        llmExplanation = humanReadableExplanation
      }
      break
    default:
      message = 'Unknown event'
  }

  return (
    <li className="w-full text-center" key={event.id}>
      <Text className="text-subtle" variant="p-xs">
        {message}
        {(explanation || llmExplanation) && (
          <Tooltip>
            <TooltipTrigger className="align-top">
              <Info className="ml-xs" size={12} />
            </TooltipTrigger>
            {llmExplanation && (
              // TODO: Handle llm explanations and other explanations generically.
              <TooltipContent className="text-left">
                {llmExplanation}
              </TooltipContent>
            )}
            {explanation && (
              // Make this more generic, but for now, we're just showing the escalation
              // reason metadata.
              <TooltipContent className="text-left">
                <div>{explanation}</div>
                {event.escalationReasonMetadata ? (
                  <div>
                    <CodeBlock>
                      {JSON.stringify(event.escalationReasonMetadata, null, 2)}
                    </CodeBlock>
                  </div>
                ) : null}
              </TooltipContent>
            )}
          </Tooltip>
        )}
        {` • ${format(event.remoteCreatedAt ?? event.createdAt, 'pp, d MMM yyyy')}`}
      </Text>
    </li>
  )
}

const ActionMessage = observer(
  ({ actionResult }: { actionResult: TicketActionResultForQA }) => {
    const ticketContext = useContext(TicketContext)

    /**
     * If we're gathering feedback, show the feedback form - but only if we're not in read-only mode.
     */
    if (
      actionResult.action.actionType === 'GatherFeedback' &&
      ticketContext &&
      !ticketContext.isReadOnly
    ) {
      const { ticketId } = actionResult.action
      return (
        <li className="w-full text-center">
          <CsatForm
            onChange={(score) => {
              void ticketContext.update(ticketId, { csatScore: score })
            }}
            value={ticketContext.csatScore}
          />
        </li>
      )
    }

    let message, details
    // Goals:
    // * Show the action if it errored
    // * Otherwise:
    //   * Show the action if the UI doesn't indicate the action -
    //     e.g. messages we've sent, notes we've posted, etc will show, so don't show those.
    //   * or if the action isn't useful to show, e.g. deleting paused workflow state.
    let shouldShowAction: boolean = !!actionResult.error
    switch (actionResult.action.actionType) {
      case 'ApplyTags':
        message = 'Applying tags'
        shouldShowAction = true
        details = (
          <>
            {actionResult.action.tags.map((tag) => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </>
        )
        break
      case 'CloseTicket':
        message = 'Closing ticket'
        shouldShowAction = true
        break
      case 'DeletePausedWorkflowRun':
        message = 'Tidying prior paused workflow state'
        break
      case 'ReassignTicket':
        message = 'Reassigning ticket'
        break
      case 'PostInternalNote':
        message = 'Posting internal note'
        break
      case 'ReplyToCustomer':
        message = 'Replying to customer'
        break
      case 'PostToSlack':
        message = 'Posting to Slack'
        shouldShowAction = true
        details = (
          <CodeBlock>
            {{
              slackChannelId: actionResult.action.channelId,
              message: actionResult.action.text,
            }}
          </CodeBlock>
        )
        break
      case 'AddMessagesToTicket':
        message = 'Added messages to test ticket'
        break
      case 'PauseWorkflowRun':
        message = 'Pausing workflow'
        details =
          actionResult.action.pauseInfo?.reason ===
          'WAITING_FOR_ASYNC_TOOL_RESPONSE'
            ? 'Waiting for tool response'
            : actionResult.action.pauseInfo?.reason ===
                'WAITING_FOR_CUSTOMER_RESPONSE'
              ? 'Waiting for customer response'
              : undefined
        shouldShowAction = true
        break
      case 'EscalateToHuman':
        message = 'Attempting to escalate'
        break
      // TODO: Show prompt info etc as applicable
      case 'ReplyToCustomerFinal':
        message = 'Generating response to customer'
        break
      case 'SetPriority':
        message = 'Setting ticket priority'
        shouldShowAction = true
        details = <CodeBlock>{actionResult.action.priority}</CodeBlock>
        break
      case 'SetIntercomCustomAttributes':
        message = 'Setting Intercom custom attributes'
        shouldShowAction = true
        details = <CodeBlock>{actionResult.action.customAttributes}</CodeBlock>
        break
      case 'GatherFeedback':
        message = 'Feedback requested'
        shouldShowAction = true
        break
      default:
        message = 'Unknown action'
        break
    }

    if (!shouldShowAction) {
      return null
    }

    return (
      <li className="w-full text-center">
        <Text color={actionResult.error ? 'error' : 'subtle'} variant="p-xs">
          {message}
          {(actionResult.error || details) && (
            <Tooltip>
              <TooltipTrigger style={{ verticalAlign: 'top' }}>
                {actionResult.error ? (
                  <AlertTriangleIcon className="ml-xs" size={12} />
                ) : (
                  <Info className="ml-xs" size={12} />
                )}
              </TooltipTrigger>
              <TooltipContent
                className={cn('max-h-80 max-w-md overflow-y-auto text-left')}
              >
                <div>{details}</div>
                {actionResult.error && (
                  <>
                    <div>
                      <Text variant="p-s-bold">Error</Text>
                      <div className="mt-xs">
                        <CodeBlock>
                          {
                            // This error is usually a DB one - don't expose that, the user
                            // can't do anything about it/shouldn't see our internals
                            actionResult.action.actionType ===
                            'DeletePausedWorkflowRun'
                              ? 'Unknown error'
                              : actionResult.error.message
                          }
                        </CodeBlock>
                      </div>
                    </div>
                    {actionResult.error.recommendedAction && (
                      <div>
                        <Text variant="p-s-bold">Recommended action</Text>
                        <div className="mt-xs">
                          <CodeBlock>
                            {actionResult.error.recommendedAction}
                          </CodeBlock>
                        </div>
                      </div>
                    )}
                    {actionResult.error.details && (
                      <div>
                        <Text variant="p-s-bold">Details</Text>
                        <div className="mt-xs">
                          <CodeBlock>
                            {JSON.stringify(
                              actionResult.error.details,
                              null,
                              2,
                            )}
                          </CodeBlock>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </TooltipContent>
            </Tooltip>
          )}
          {` • ${format(actionResult.endTime, 'pp, d MMM yyyy')}`}
        </Text>
      </li>
    )
  },
)

/**
 * The overall container for the message list, message name and related metadata.
 */
const AppMessageWidget = ({
  customerDisplayName,
  ticketRemoteId,
  messages,
  isLoading,
  events = [],
  actions,
  ticketTitle,
  voiceRecordingUrl,
}: MessageListProps) => {
  return (
    <div
      className={cn(
        'grid h-full grid-rows-[auto_1fr] overflow-y-auto rounded-t-md border border-b-0 border-solid bg-surface-card last:rounded-b-md last:border-b',
      )}
    >
      <div className="flex flex-row items-center justify-between gap-xs border-b border-solid bg-surface-card p-m">
        <div>
          <Heading variant="h2">
            {customerDisplayName ?? `Ticket ${ticketRemoteId ?? ''}`}
          </Heading>
          {ticketTitle && (
            <Text
              className="line-clamp-1 text-ellipsis text-subtle"
              variant="p-s"
            >
              {ticketTitle}
            </Text>
          )}
        </div>
        <div>
          {voiceRecordingUrl && (
            <Link prefetch="intent" target="_blank" to={voiceRecordingUrl}>
              <Button variant="outline">Download voice recording</Button>
            </Link>
          )}
        </div>
      </div>
      <MessageThread
        actions={actions}
        events={events}
        hasEscalated={events.some(
          (event) => event.escalationReason !== undefined,
        )}
        isLoading={isLoading}
        messages={messages}
      />
    </div>
  )
}

export interface MessageThreadProps {
  actions: TicketActionsResultsForQA
  /**
   * Events related to the ticket.
   * @default []
   */
  events?: TicketEventForQA[]
  hasEscalated?: boolean

  /**
   * The side of the author avatar. Defaults to right.
   */
  invertMessageAlignment?: boolean
  isLoading?: boolean

  messages: MessageProps[]
}

const ToolMessage = ({ message }: { message: MessageProps }) => {
  const messageData = JSON.parse(message.content)
  const toolName =
    message.messageType === 'INTERNAL_TOOL_INVOCATION_REQUEST'
      ? messageData.function.name
      : messageData.name

  return (
    <div className="relative -mx-l my-l !mt-xl border-t py-l">
      <Tooltip>
        <TooltipTrigger className="absolute -top-l left-0 flex w-full flex-col items-center justify-center gap-y-xs text-center">
          <Tool name={toolName || 'Internal Tool Invocation'} />
          {message.createdAt && (
            <Text color="subtle" variant="p-xs">
              {message.messageType === 'INTERNAL_TOOL_INVOCATION_REQUEST'
                ? 'Tool called '
                : 'Tool returned '}
              • {format(message.createdAt, 'pp, d MMM yyyy')}
            </Text>
          )}
        </TooltipTrigger>
        <TooltipContent className="max-h-80 max-w-md overflow-y-auto">
          <CodeBlock>
            {message.messageType === 'INTERNAL_TOOL_INVOCATION_REQUEST'
              ? messageData.function.arguments
              : messageData.response}
          </CodeBlock>
        </TooltipContent>
      </Tooltip>
    </div>
  )
}

export const MessageThread = ({
  messages,
  isLoading,
  events = [],
  actions,
  invertMessageAlignment,
  hasEscalated,
}: MessageThreadProps) => {
  // Message createdAt shouldn't be nullable...check why they are here.
  const listItems = [
    ...messages.map((message) => {
      return {
        type: 'message' as const,
        timestamp: message.remoteCreatedAt ?? message.createdAt!,
        message,
      }
    }),
    ...events.map((event) => {
      return {
        type: 'event' as const,
        timestamp: event.remoteCreatedAt ?? event.createdAt,
        event,
      }
    }),
    ...actions.actionsTaken.map((action) => {
      return {
        type: 'action' as const,
        timestamp: action.startTime,
        actionResult: action,
      }
    }),
    ...(actions.actionError
      ? [
          {
            type: 'action' as const,
            timestamp: actions.actionError.startTime,
            actionResult: actions.actionError,
          },
        ]
      : []),
  ]

  const displayMessages = [...listItems].sort(
    (a, b) =>
      (new Date(a.timestamp).valueOf() ?? 0) -
      (new Date(b.timestamp).valueOf() ?? 0),
  )

  return (
    <InvertAlignmentContext.Provider value={invertMessageAlignment}>
      <ol className="space-y-l bg-surface-sunken p-l">
        {displayMessages.map((item, i) => {
          if (item.type === 'message') {
            const message = item.message
            switch (message.messageType) {
              case 'CUSTOMER':
                return (
                  <li key={message.id}>
                    <CustomerMessage message={message} />
                  </li>
                )
              case 'USER':
                // For backwards compatibility.
                return (
                  <li key={message.id}>
                    {message.isFromBot ? (
                      <LorikeetMessage message={message} />
                    ) : (
                      <UserMessage message={message} />
                    )}
                  </li>
                )
              case 'BOT_RESPONSE':
                return (
                  <li key={message.id}>
                    <LorikeetMessage message={message} />
                  </li>
                )
              case 'PENDING_RESPONSE':
                return (
                  <li key={message.id}>
                    <LorikeetMessage key={message.id} message={message} />
                  </li>
                )
              case 'DRAFT_RESPONSE':
                return (
                  <LorikeetMessage isDraft key={message.id} message={message} />
                )
              case 'INTERNAL_USER_NOTE':
                // For backwards compatibility.
                return message.isFromBot ? (
                  <LorikeetMessage
                    isInternal
                    key={message.id}
                    message={message}
                  />
                ) : (
                  <UserMessage isInternal key={message.id} message={message} />
                )
              case 'INTERNAL_MODEL_NOTE':
              case 'INTERNAL_MODEL_PRIVATE_NOTE':
                return (
                  <li key={message.id}>
                    <LorikeetMessage isInternal message={message} />
                  </li>
                )
              case 'INTERNAL_TOOL_INVOCATION_DATA':
              case 'INTERNAL_TOOL_INVOCATION_REQUEST':
                return (
                  <li key={message.id}>
                    <ToolMessage message={message} />
                  </li>
                )
              case 'UNKNOWN':
                return <UnknownMessage key={message.id} message={message} />
            }
          }

          if (item.type === 'event') {
            return <TicketEventMessage event={item.event} key={item.event.id} />
          }
          return <ActionMessage actionResult={item.actionResult} key={i} />
        })}
        {isLoading && !hasEscalated && (
          <li key="loader">
            <LorikeetMessage isLoading key="loader" />
          </li>
        )}
      </ol>
    </InvertAlignmentContext.Provider>
  )
}

export { AppMessageWidget as MessageList }
