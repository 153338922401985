import { ButtonIcon, cn, Text } from '@optechai/design-system'
import type { ButtonIconProps } from '@optechai/design-system/src/components/ui/button-icon/button-icon'
import {
  AngryIcon,
  FrownIcon,
  LaughIcon,
  MehIcon,
  SmileIcon,
} from 'lucide-react'

import type { CsatScore } from '@server/models/ticket/ticket.dto'

type CsatFormProps = {
  value: CsatScore | undefined
  onChange: (value: CsatScore) => void
}
const RATING_LABELS = {
  1: {
    label: 'Very dissatisfied',
    icon: AngryIcon,
    variant: 'rating-negative',
    className: 'border-none',
  },
  2: {
    label: 'Dissatisfied',
    icon: FrownIcon,
    className: 'text-orange-600 hover:bg-orange-200',
  },
  3: {
    label: 'Neither satisfied nor dissatisfied',
    icon: MehIcon,
    className: '',
  },
  4: {
    label: 'Satisfied',
    icon: SmileIcon,
    className: 'text-green-600 hover:bg-green-200',
  },
  5: {
    label: 'Very satisfied',
    icon: LaughIcon,
    variant: 'rating-positive',
    className: 'border-none',
  },
} satisfies Record<CsatScore, Partial<ButtonIconProps>>

export const CsatForm = ({ value, onChange }: CsatFormProps) => {
  return (
    <div className="flex flex-col items-center gap-y-s">
      <Text color="subtle" variant="p-xs">
        Rate your conversation
      </Text>
      <ol className="mx-auto flex max-w-60 gap-xxs rounded border border-border p-s">
        {Object.entries(RATING_LABELS).map(([score, options]) => (
          <li key={score}>
            <ButtonIcon
              className={cn(options.className)}
              icon={options.icon}
              label={options.label}
              onClick={() => onChange(parseInt(score) as CsatScore)}
              size="large"
              state={value === parseInt(score) ? 'selected' : 'default'}
              variant={'variant' in options ? options.variant : undefined}
            />
          </li>
        ))}
      </ol>
    </div>
  )
}
