import { Text } from '@optechai/design-system'
import { FileCode } from 'lucide-react'

import type { TicketMessageAttachment } from '@server/models/ticketMessage/ticketMessageAttachment.dto'

function isImage(type: string) {
  return type.startsWith('image/')
}

/**
 * __MediaCard__
 */
export const MediaCard = (attachment: TicketMessageAttachment) => {
  return (
    <a
      className="overflow-hidden rounded-sm bg-surface-card p-s text-link"
      href={attachment.url}
      key={attachment.id}
      rel="noopener noreferrer"
      target="_blank"
    >
      {isImage(attachment.type) ? (
        <img alt={attachment.name} src={attachment.url} />
      ) : (
        <span className="flex items-start gap-xs">
          <FileCode className="min-h-6 min-w-6 text-secondary" />
          <span className="flex flex-col gap-xxs">
            <Text className="text-nowrap" color="default" variant="p-s">
              {attachment.name}
            </Text>
            <Text color="subtle" variant="p-s">
              {attachment.type}
            </Text>
          </span>
        </span>
      )}
    </a>
  )
}
